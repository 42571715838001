import Shimmer from './shimmer';
import haveDesignSystem from './helper/have_design_system';

const shimmer = new Shimmer();

onLoad(($) => {
  if (!haveDesignSystem()) {
    return;
  }

  const toggleBalanceValue = () => {
    const $balanceValue = $('.JS-balance-info-value');
    const $balanceValueReference = $('.balance-info-reference span');

    const hideValue = ($balance) => {
      $balance.addClass('ds-none');
    };

    const showValue = ($balance, $reference) => {
      const value = $reference.text().split(':')[1].trim();
      let cleanedValue = value
        .replace('R$', '')
        .trim()
        .replace(/\./g, '')
        .replace(',', '.');

      if (value.includes('-')) {
        cleanedValue = `-${cleanedValue.replace(/-/g, '')}`;
      }

      const displayValue = cleanedValue > 0 ? value : 'R$ 0,00';

      const defineTemplate = `
        <strong id="balance-available-value" class="value ${parseFloat(cleanedValue) > 0 ? 'positive' : ''}">
          ${displayValue}
        </strong>`;

      $balance.each(function () {
        $(this).html(defineTemplate);
      });
    };

    if ($balanceValue.length) {
      hideValue($balanceValueReference);
      showValue($balanceValue, $balanceValueReference);
    }
  };

  $('.toggle-balance-icon.JS-toggle-balance').on('click', function (e) {
    e.preventDefault();
    shimmer.apply($(this));
  });

  toggleBalanceValue();

  shimmer.initialize();
});

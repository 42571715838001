import MultiselectCheckbox from '../multiselect_checkbox';
import CurrencyHelper from '../helper/currencyHelper';

function toggleSummarySelected($summarySelected, quant, value, hasChecked) {
  if (!$summarySelected.length) return;

  const currencyHelper = new CurrencyHelper();
  const quantText = $summarySelected.find('.selected-count');
  const valueText = $summarySelected.find('.total-value-selected');
  const plural = quant === 1 ? 'ão' : 'ões';

  $summarySelected[`${!hasChecked ? 'add' : 'remove'}Class`]('d-none');
  quantText.text(`${quant} solicitaç${plural}`);
  valueText.text(currencyHelper.format(value));
}

function updateState(multiselect) {
  let params = '';
  let totalValue = 0;
  const quantSelected = multiselect.checkedItemsQuantity();
  const $summarySelected = $('.summary-concessions-selected');

  if (multiselect.hasCheckedItems()) {
    multiselect.getCheckedItems().each((_, checkedItem) => {
      const $checkedItem = $(checkedItem);
      params += `ids[]=${$checkedItem.val()}&`;
      totalValue += parseFloat($checkedItem.data('value'));
    });
  }

  toggleSummarySelected($summarySelected, quantSelected, totalValue, multiselect.hasCheckedItems());

  multiselect.$actionButtons.each((_, button) => {
    const $btn = $(button);
    const url = $btn.attr('href').split('?')[0];
    const type = $btn.data('type');
    const text = type === 'approve_all' ? 'aprovar' : 'rejeitar';

    $btn.attr('href', `${url}?${params}`);

    const confirmText = `
      <div class="swal-confirm-modal">
        <div class="content">
          Tem certeza que deseja ${text} esta solicitação de cessão?
      </div>
    `;

    $btn.data('html', confirmText);
    $btn.data('confirm-button', `Sim, ${text}`);
  });
}

onLoad(($) => {
  $('#select_all_charge_concessions').each((_, element) => new MultiselectCheckbox($(element), { updateState }));
});

/* eslint-disable array-callback-return, consistent-return */

onLoad(($) => {
  const $buttonExternalOnboarding = $('.JS-button-external-onboarding');
  if (!$buttonExternalOnboarding.length) return;

  const $formExternalOnboarding = $('#new_client_requested_registration');
  const $buttonExternalOnboardingContinue = $('#JS-button-external-onboarding-continue');
  const $confirmInviteModal = $('.JS-confirm-invite-modal');

  function isInputsValid() {
    const $inputs = $('.form-control');
    const $inputsInvalid = $inputs.map(function () {
      const $input = $(this);
      if ($input.val() === '') {
        if (!$input.parent().find($('.invalid-feedback')).length) {
          $input.addClass('is-invalid');
          $input.parent().append('<div class="invalid-feedback d-block">Campo obrigatório</div>');
        }
        return $input;
      }
    });
    return !$inputsInvalid.length;
  }

  $buttonExternalOnboarding.on('click', (event) => {
    event.preventDefault();
    if (isInputsValid()) {
      $confirmInviteModal.modal({ show: true });
    }
  });

  $buttonExternalOnboardingContinue.on('click', (event) => {
    event.preventDefault();
    if (isInputsValid()) {
      $formExternalOnboarding.submit();
    }
  });
});

onLoad(($) => {
  const $container = $('.JS-tooltip-handler');

  if (!$container.length) return;

  const tooltipComponents = ($tooltips) => {
    $tooltips.tooltip('hide');
  };

  $('[data-toggle="modal"]').on('click', () => {
    tooltipComponents($container.find('[data-tt="tooltip"]'));
    tooltipComponents($container.find('[data-toggle="tooltip"]'));
  });
});

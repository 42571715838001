import CurrencyHelper from '../helper/currencyHelper';

onLoad(($) => {
  if (!$('.ds-select-beneficiary').length) return;

  const $beneficiarySelect = $('#asset_disposal_beneficiary_id');
  const $confirmTransferBtn = $('.JS-confirm-btn');
  const $costOwnershipLink = $('.cost_ownership_link');
  const $errorContainer = $('.error-container');
  const $ownershipLabel = $('#ownership_label');
  const $simulatedConcessionCost = $('#simulated_concession_cost');

  const currencyHelper = new CurrencyHelper();

  function consultBeneficiary(id) {
    $.ajax({
      url: '/charge/asset_disposal/select_beneficiary_async',
      type: 'GET',
      dataType: 'json',
      data: { beneficiary_id: id },
      success(data) {
        $confirmTransferBtn.removeClass('btn-disabled');
        $costOwnershipLink[`${data.cost_ownership ? 'remove' : 'add'}Class`]('d-none');
        $errorContainer.addClass('d-none');
        $ownershipLabel.text(data.ownership_label);

        if (data.cost_ownership) {
          const billingPolicyKeys = Object.keys(data.billing_policy);
          billingPolicyKeys.forEach((policyKey) => {
            const idDiv = $(`#${policyKey}`);
            idDiv.text(data.billing_policy[policyKey]);
          });
          $simulatedConcessionCost.text(currencyHelper.format(data.simulated_concession_cost));
        }
      },
      error(data) {
        const errorMessage = data.responseJSON.error_message;

        $confirmTransferBtn.addClass('btn-disabled');
        $costOwnershipLink.addClass('d-none');
        $errorContainer.removeClass('d-none');
        $errorContainer.text(errorMessage);
        $ownershipLabel.text('-');
      },
    });
  }

  $beneficiarySelect.on('change', (e) => {
    const id = e.currentTarget.value;

    if (!id.length) {
      $confirmTransferBtn.addClass('btn-disabled');
      $costOwnershipLink.addClass('d-none');
      $ownershipLabel.text('-');
      $errorContainer.addClass('d-none');
      return;
    }

    consultBeneficiary(id);
  });
});

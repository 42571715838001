import CurrencyHelper from './helper/currencyHelper';

onLoad(($) => {
  const $inputMoneyAlt = $('.input-money-alt');

  if (!$inputMoneyAlt.length) return;

  const currencyHelper = new CurrencyHelper();

  const $balanceAvailable = $('#balance-available-value').text();
  const $groupPrepend = $inputMoneyAlt.find('.input-group-text');
  const $input = $inputMoneyAlt.find('input');
  const $inputGroup = $inputMoneyAlt.find('.input-group');
  const $inputMoneyAltContinueBtn = $('.JS-input-money-alt-continue-btn');

  $input.mask('#.##0,00', { reverse: true, placeholder: '0,00' });

  function verifyInputValue(value) {
    const verifyString = ['00,0', '00,', '0'];
    let invalid = false;

    verifyString.forEach((element) => {
      if (value.slice(0, 4).includes(element) && value[0] === '0') {
        invalid = true;
      }
    });

    return invalid;
  }

  $input.on('input', (event) => {
    const balanceValue = currencyHelper.unformat($balanceAvailable.replace('R$', ''));
    const value = currencyHelper.unformat(event.target.value);

    $inputGroup.find('.invalid-feedback').remove();

    if (Number.isNaN(value) || value === 0) {
      $groupPrepend.addClass('text-disabled');
      $input.addClass('text-disabled');
      $inputMoneyAltContinueBtn.addClass('btn-disabled');
      $input.val('0,00').change();
      return;
    }

    if (verifyInputValue(event.target.value)) {
      const currencyValue = currencyHelper.format(value).replace('R$', '').trim();
      $input.val(currencyValue).change();
    }

    if (value > balanceValue && !$inputMoneyAlt.hasClass('JS-qr_code_pix_receiveds')) {
      $groupPrepend.removeClass('text-disabled');
      $input.removeClass('text-disabled');
      $inputGroup.append('<div class="invalid-feedback d-block">Saldo insuficiente</div>');
      $inputMoneyAltContinueBtn.addClass('btn-disabled');
      return;
    }

    $groupPrepend.removeClass('text-disabled');
    $input.removeClass('text-disabled');
    $inputMoneyAltContinueBtn.removeClass('btn-disabled');
  });
});

import CurrencyHelper from './helper/currencyHelper';

onLoad(($) => {
  const btnSelectBatch = $('#select_batch');

  if (!btnSelectBatch.length) return;

  localStorage.clear();

  const btnSelectBatchAction = $('.btn-select-batch-action');

  let selected = [];

  // #region Getters
  const getCurrentSelectedItemsUUID = () => {
    const $hiddenItems = $('.hidden-items');

    if (!$hiddenItems.length) return [];

    return $hiddenItems.val().split(',').filter(uuid => uuid !== '');
  };

  const areAllRowsSelected = () => {
    const isAllRowsSelectedByBatchAction = JSON.parse(localStorage.getItem('selectAllRows'));

    if (isAllRowsSelectedByBatchAction) return isAllRowsSelectedByBatchAction;

    const totalRows = $('#total_count').val();
    const totalRowsSelected = getCurrentSelectedItemsUUID().length;

    return Number(totalRows) === totalRowsSelected && totalRowsSelected > 0;
  };

  const getCheckBoxValues = () => JSON.parse(localStorage.getItem('checkboxValues')) || {};

  const getCurrentPageCheckboxes = () => {
    const $selectItems = $('.selected-items');

    if (!$selectItems.length) return [];

    return $selectItems;
  };

  const getCurrentCheckedCheckboxes = () => {
    const $selectItems = $('.selected-items:checked');

    if (!$selectItems.length) return [];

    return $selectItems;
  };
  // #endregion Getters

  const checkUnchecked = (isChecked) => {
    getCurrentPageCheckboxes().prop('checked', isChecked);
  };

  const updateSelectedPageCounter = () => {
    const $selectBatchComponent = $('.JS-resources-select-all-rows');

    if (!$selectBatchComponent.length) return;

    const totalSelectOnPage = getCurrentCheckedCheckboxes().length;

    $selectBatchComponent.find('.current-page-amount').html(totalSelectOnPage);
  };

  const updateSelectedCounter = () => {
    const $selectBatchComponent = $('.JS-resources-select-all-rows');

    if (!$selectBatchComponent.length) return;

    const allRowsSelected = areAllRowsSelected();

    let checkedValues = 0;

    if (allRowsSelected) {
      const totalRows = $('#total_count').val();

      checkedValues = totalRows;
    } else {
      checkedValues = Object.values(getCheckBoxValues()).filter(v => v.checked).length;
    }

    $selectBatchComponent.find('.current-selected-amount').html(checkedValues);
  };

  const toggleComponentBatchTextVisibility = (isAllRowsSelected) => {
    const $selectBatchComponent = $('.JS-resources-select-all-rows');

    if (!$selectBatchComponent.length) return;

    const $undoSelectionText = $selectBatchComponent.find('.undo-selection-text');
    const $selectBatchText = $selectBatchComponent.find('.select-batch-text');

    if (isAllRowsSelected) {
      $undoSelectionText.addClass('d-inline').removeClass('d-none');
      $selectBatchText.addClass('d-none').removeClass('d-inline');
    } else {
      $selectBatchText.addClass('d-inline').removeClass('d-none');
      $undoSelectionText.addClass('d-none').removeClass('d-inline');
    }
  };

  const toggleComponentText = (showDefaultText) => {
    const $selectBatchComponent = $('.JS-resources-select-all-rows');

    if (!$selectBatchComponent.length) return;

    const $selectBatchText = $selectBatchComponent.find('.select-batch-text');
    const $defaultText = $selectBatchText.find('.default-text');
    const $manySelectedText = $selectBatchText.find('.many-selected-text');

    if (showDefaultText) {
      $defaultText.addClass('d-inline').removeClass('d-none');
      $manySelectedText.addClass('d-none').removeClass('d-inline');
    } else {
      $defaultText.addClass('d-none').removeClass('d-inline');
      $manySelectedText.addClass('d-inline').removeClass('d-none');

      updateSelectedCounter();
    }
  };

  const toggleBatchSelectorComponentVisibility = (isParentChecked) => {
    const $selectAllRowsComponent = $('.JS-resources-select-all-rows');

    if (!$selectAllRowsComponent.length) return;

    updateSelectedPageCounter();

    if (isParentChecked) {
      $selectAllRowsComponent.addClass('d-flex').removeClass('d-none');
    } else {
      $selectAllRowsComponent.addClass('d-none').removeClass('d-flex');
    }
  };

  const toggleBtnSelectBatchAction = (toggle) => {
    btnSelectBatchAction.toggleClass('btn-disabled', toggle);
  };

  const clearSelection = () => {
    toggleBatchSelectorComponentVisibility(false);
    toggleComponentBatchTextVisibility(false);
    toggleBtnSelectBatchAction(true);
    checkUnchecked(false);

    localStorage.removeItem('checkboxValues');
    localStorage.removeItem('selectAllRows');

    btnSelectBatch.prop('checked', false);

    selected = [];
    $('.hidden-items').val('');
  };

  const verifyCurrentSelectionOnPage = () => {
    const currentPageCheckboxes = getCurrentPageCheckboxes().length;
    const currentPageCheckedCheckboxes = getCurrentCheckedCheckboxes().length;
    const totalSelected = Object.values(getCheckBoxValues()).filter(v => v.checked).length;
    const isPageselected = currentPageCheckboxes === currentPageCheckedCheckboxes;

    if (isPageselected) {
      btnSelectBatch.prop('checked', true);
      toggleBatchSelectorComponentVisibility(true);
    }

    toggleComponentText(!(totalSelected > currentPageCheckedCheckboxes));
  };

  const verifyCurrentSelectedItems = () => {
    const allRowsSelected = areAllRowsSelected();

    if (allRowsSelected) {
      toggleBatchSelectorComponentVisibility(allRowsSelected);
      toggleComponentBatchTextVisibility(allRowsSelected);
      localStorage.setItem('selectAllRows', allRowsSelected);
      btnSelectBatch.prop('checked', allRowsSelected);
    }
  };

  const updateSelectedChargesTotalValue = (total) => {
    $('#total-charges-selected').text(total);
  };

  const updateSelectedChargesCount = (count) => {
    $('#charges-selected-count').text(count);
  };

  const onSelectBatch = () => {
    localStorage.setItem('selectAllRows', true);

    selected = [];
    $('.hidden-items').val(selected);

    verifyCurrentSelectedItems();

    const $totalAvaibleCatchingValue = $('#total_available_value');

    if (!$totalAvaibleCatchingValue.length) return;

    const currencyHelper = new CurrencyHelper();
    const totalRows = $('#total_count').val();
    const plural = Number(totalRows) === 1 ? '' : 's';

    updateSelectedChargesTotalValue(
      currencyHelper.format($totalAvaibleCatchingValue.val()),
    );
    updateSelectedChargesCount(`${totalRows} título${plural}`);
    updateSelectedCounter();
  };

  const resetLocalStorage = () => {
    localStorage.removeItem('checkboxValues');
  };

  const onUndoSelectBatch = () => {
    clearSelection();

    const selectedChargesSummary = $('#summary-charges-selected');

    if (!selectedChargesSummary.length) return;

    selectedChargesSummary.toggleClass('d-none', true);
  };

  const updateLocalStorage = () => {
    const checkboxValues = getCheckBoxValues();

    getCurrentPageCheckboxes().each((i, e) => {
      const element = $(e);
      checkboxValues[element.attr('id')] = {
        checked: selected.includes(element.val()),
        value: element.data('value'),
      };
    });

    localStorage.setItem('checkboxValues', JSON.stringify(checkboxValues));
  };

  const setSelectedChargesUUIDs = () => {
    const isAllRowsSelectedByBatchAction = JSON.parse(localStorage.getItem('selectAllRows'));

    if (isAllRowsSelectedByBatchAction) return;

    getCurrentPageCheckboxes().each((_, element) => {
      const $element = $(element);
      const value = $element.val();
      const isChecked = $element.is(':checked');

      if (isChecked && !selected.includes(value)) {
        selected.push(value);
        return;
      }

      if (!isChecked && selected.includes(value)) {
        selected = selected.filter(item => item !== value);
      }
    });

    $('.hidden-items').val(selected);
  };

  const setSelectedChargesTotalValue = (checkedValues) => {
    const totalValue = checkedValues.reduce((acc, curr) => acc + parseFloat(curr.value), 0);

    const currencyHelper = new CurrencyHelper();
    updateSelectedChargesTotalValue(currencyHelper.format(totalValue));
  };

  const setSelectedChargesCount = (selectedCount) => {
    const plural = selectedCount === 1 ? '' : 's';

    updateSelectedChargesCount(`${selectedCount} título${plural}`);
  };

  const setSelectedChargesSummary = () => {
    const selectedChargesSummary = $('#summary-charges-selected');

    if (!selectedChargesSummary.length) return;

    const checkedValues = Object.values(getCheckBoxValues()).filter(v => v.checked);
    setSelectedChargesTotalValue(checkedValues);

    const selectedCount = checkedValues.length;
    setSelectedChargesCount(selectedCount);

    selectedChargesSummary.toggleClass('d-none', !selectedCount);
  };

  const onAnyCheckChange = () => {
    setSelectedChargesUUIDs();
    verifyCurrentSelectedItems();
    updateLocalStorage();
    setSelectedChargesSummary();
  };

  const onParentCheckChange = (e) => {
    const $checkbox = $(e.target);

    if (!$checkbox.length) return;

    const isAllRowsSelectedByBatchAction = JSON.parse(localStorage.getItem('selectAllRows'));

    if (isAllRowsSelectedByBatchAction) clearSelection();

    const isChecked = $checkbox.is(':checked');

    if (!isChecked) localStorage.setItem('selectAllRows', false);

    checkUnchecked(isChecked);
    onAnyCheckChange();
    toggleBtnSelectBatchAction(!getCurrentSelectedItemsUUID().length);
    updateSelectedCounter();

    const hasMorePages = $('.pagination-footer').is(':visible');

    if (!hasMorePages) {
      toggleBatchSelectorComponentVisibility(false);
      return;
    }

    const checkedValues = Object.values(getCheckBoxValues()).filter(v => v.checked);

    toggleBatchSelectorComponentVisibility(checkedValues.length > 0);
    verifyCurrentSelectionOnPage();
  };

  const onPageLinkClick = () => {
    toggleComponentText(false);

    if (areAllRowsSelected()) {
      btnSelectBatch.prop('checked', true);
      return;
    }

    btnSelectBatch.prop('checked', false);
    toggleComponentBatchTextVisibility(false);
  };

  const onChangeChildrenCheckbox = (e) => {
    if (areAllRowsSelected()) {
      const $checkbox = $(e.target);

      clearSelection();

      btnSelectBatch.trigger('change');

      $checkbox.prop('checked', true);

      localStorage.setItem('selectAllRows', false);
      btnSelectBatch.prop('checked', false);
    }

    onAnyCheckChange();
    updateSelectedCounter();
    toggleBtnSelectBatchAction(!getCurrentSelectedItemsUUID().length);
    verifyCurrentSelectionOnPage();
  };

  // #region Init Event Handler
  const initEventHandler = () => {
    btnSelectBatch.on('change', onParentCheckChange);
    $('body').on('change', '.selected-items', onChangeChildrenCheckbox);
    $('#paginator').on('click', '.page-link', onPageLinkClick);
    $('.JS-resources-select-all-rows').on('click', '.select-batch', onSelectBatch);
    $('.JS-resources-select-all-rows').on('click', '.undo-selection', onUndoSelectBatch);
    $('body').on('click', '#summary-charges-selected .undo-selection-action', onUndoSelectBatch);
  };
  // #region Init Event Handler

  // #region Init Observer
  const initObserver = () => {
    const $body = $('body');

    const documentObserver = new MutationObserver(() => {
      const $resourcesTable = $body.find('.JS-resources-table');

      if ($resourcesTable.length > 0) {
        const isAllRowsSelectedByBatchAction = JSON.parse(localStorage.getItem('selectAllRows'));

        if (isAllRowsSelectedByBatchAction) checkUnchecked(true);
      }
    });

    const options = {
      attributes: false, childList: true, characterData: false, subtree: true,
    };

    documentObserver.observe(document, options);
  };
  // #endregion Init Observer

  resetLocalStorage();
  initObserver();
  initEventHandler();
});

onLoad(($) => {
  const $filters = $('.JS-pix-history-filters');

  if (!$filters.length) return;

  const $requestType = $filters.find('#q_source_cd_eq');
  const $transferResquestImport = $filters.find('#q_pix_transfer_request_import_id_or_transfer_request_import_id_eq');
  const $dateType = $filters.find('#date_type');
  const $dateStart = $filters.find('#q_start_date');
  const $dateEnd = $filters.find('#q_end_date');

  $requestType.on('change', (e) => {
    const $select = $(e.currentTarget);

    if (!$select.length) return;

    const value = $select.find(':selected').val();
    const canDisable = value !== 'import_file';

    $transferResquestImport.prop('disabled', canDisable);

    if (canDisable) $transferResquestImport.val('');
  });

  $dateType.on('change', (e) => {
    const $select = $(e.currentTarget);

    if (!$select.length) return;

    const value = $select.find(':selected').val();
    const canDisable = value === '';

    $dateStart.prop('disabled', canDisable);
    $dateEnd.prop('disabled', canDisable);

    if (canDisable) {
      $dateStart.val('');
      $dateEnd.val('');
    }
  });

  $requestType.trigger('change');
});

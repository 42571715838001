onLoad(($) => {
  const $swalReceipt = () => $('.JS-swal-receipt-send-pdf');
  if (!$swalReceipt().length) return;

  const params = new URLSearchParams(window.location.search);
  const transferId = params.get('transfer_created');
  const $pagination = $('#paginator');

  const handleReceipt = () => {
    $swalReceipt().on('click', function receiptClick(e) {
      e.preventDefault();
      const url = $(this).attr('href');

      const title = $(this).attr('data-title') || '2ª via do comprovante';

      Swal.fire({
        html: `<div>
                  <div class="row no-print">
                    <div class="col-lg-7 text-left pt-1">
                        <div style="font-size: 16px;font-weight: bold;">${title}</div>
                    </div>
                    <div class="col-lg-5 text-right pb-1">
                        <a href="${url}/export_pdf" class="btn btn-primary btn-sm" target="_blank">
                          <span class="fas fa-print"></span>
                        </a>
                    </div>
                  </div>
                </div>
                <iframe id="JS-iframe-receipt" src="${url}" width="490px" height="690px"
                    onload="this.style.height=this.contentDocument.body.scrollHeight+60 +'px';">
                </iframe>`,
        showCloseButton: false,
        focusConfirm: true,
        width: '590px',
        heigth: '840px',
        customClass: {
          confirmButton: 'no-print',
          closeButton: 'no-print',
        },
        onOpen: () => {
          const $iFrame = $('#JS-iframe-receipt');
          $iFrame.on('load', () => {
            $iFrame.css({ 'border-width': '0px' });
          });
        },
      });
    });

    if (transferId) { $swalReceipt().filter(`#transfer-${transferId}`).trigger('click'); }
  };

  const handlePagination = () => {
    if (!$pagination.length) return;
    const observer = new MutationObserver(() => handleReceipt());
    const observerOptions = { attributes: false, childList: true, subtree: true };
    observer.observe($pagination[0], observerOptions);
  };

  handleReceipt();
  handlePagination();
});

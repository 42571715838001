onLoad(($) => {
  const $closingReason = $('.JS-closing-reasons');

  if (!$closingReason.length) return;

  const $form = $closingReason.find('.edit_ip_bank_account');
  const $radioButtons = $form.find("input[type='radio']");
  const $hiddenDescriptionField = $form.find('.hidden-description-field');
  const $textareaContainers = $('.textarea-alt');
  const $textareas = $textareaContainers.find('textarea');
  const $termsCheckbox = $form.find('#accept-closing-terms');
  const $submitButton = $form.find('input[type="submit"]');

  const toggleDisableSubmit = (disabled) => {
    $submitButton.toggleClass('btn-custom-disabled', !disabled);
    $submitButton.prop('disabled', !disabled);
  };

  const validateForm = () => {
    const reason = $radioButtons.filter(':checked').val();
    const description = $hiddenDescriptionField.val();
    const termsAccepted = $termsCheckbox.is(':checked');

    toggleDisableSubmit(
      reason !== ''
      && description !== ''
      && termsAccepted,
    );
  };

  const verifyExistingReason = () => {
    const $checkedRadio = $radioButtons.filter(':checked');
    const reason = $checkedRadio.data('reason');
    const $textarea = $(`#textarea_${reason}`);
    const description = $hiddenDescriptionField.val();

    if (!$textarea.length) return;

    $textarea.show();
    $textarea.find('textarea').val(description).trigger('input');
  };

  $radioButtons.on('change', (e) => {
    const $radio = $(e.currentTarget);

    if (!$radio.length) return;

    const reason = $radio.data('reason');
    const $currentTextarea = $(`#textarea_${reason}`);

    $textareaContainers.each((_, textarea) => {
      const $textarea = $(textarea).find('textarea');
      const $labelCharacters = $(textarea).find('.JS-textarea-alt-label-characters');
      const maxCharacters = $textarea.attr('maxlength');

      $textarea.val('');
      $labelCharacters.text(`${$textarea.val().length} / ${maxCharacters}`);
    });
    $textareaContainers.hide();
    $currentTextarea.show();
    $hiddenDescriptionField.val('');

    validateForm();
  });

  $textareas.on('keyup', (e) => {
    const $textarea = $(e.currentTarget);

    if (!$textarea.length) return;

    $hiddenDescriptionField.val($textarea.val());

    validateForm();
  });

  $termsCheckbox.on('change', validateForm);

  verifyExistingReason();
});
